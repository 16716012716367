/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0; }

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none; }

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth; }

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto; }

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block; }

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit; }

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto; }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

@font-face {
  font-family: 'hammersmith-one';
  src: url("fonts/HammersmithOne-Regular.ttf") format("truetype"); }

body {
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 2.5rem; }

.season {
  background-color: #FF6666;
  color: #000205;
  padding: 0.25em 0.5em;
  border-radius: 2px;
  position: absolute;
  top: 40%;
  transform: rotate(-10deg);
  font-weight: bold; }
  .season a {
    text-decoration: none;
    color: #000205; }
    .season a:hover {
      color: #fff; }
  .season:hover {
    background-color: #FF1A1A; }

h1 {
  font-family: hammersmith-one;
  color: #fff;
  font-size: 10rem;
  font-weight: normal;
  line-height: 1; }

h2 {
  font-family: hammersmith-one;
  color: #fff;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.2;
  text-align: center; }
  h2 a {
    display: block;
    padding: 7px 10px 5px 10px;
    background-color: #cc0000;
    color: white;
    text-decoration: none; }

footer {
  color: #fff;
  text-align: right; }
  footer a {
    color: #fff;
    text-decoration: none; }

footer a:hover,
footer a:focus,
footer a:active {
  color: #D1E0FF;
  text-decoration: none; }

@media only screen and (min-width: 1023px) {
  h1 {
    font-size: 18rem; }
  h2 {
    font-size: 2.5rem; } }

footer {
  position: absolute;
  bottom: 0;
  background-color: #000205;
  width: 100vw;
  padding: 0.5em 1em;
  font-size: 1rem;
  text-align: center; }

body {
  background-color: #0033A0; }

.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center center; }
